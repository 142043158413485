/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
	// All pages
	'common': {
		// JavaScript to be fired on all pages
		init: function() {

			$(".slick").each( function() {

  			var slickSettings = {};

  			if ( $(this).siblings(".carousel-arrow-container").length ) {

    			slickSettings = {
      			appendArrows: $(this).siblings(".carousel-arrow-container").find(".carousel-arrows")
    			};

  			}

  			if ( $(this).hasClass('cover-slider') ) {
    			slickSettings.arrows = false;
    			slickSettings.autoplay = true;
    			slickSettings.autoplaySpeed = 5000;
    			slickSettings.mobileFirst = true;
  			}

  			$(this).slick(slickSettings);

  			if ( $(this).parent().siblings('.controls').length ) {

    			var thisSlick = $(this);

    			$(this).parent().siblings('.controls').find('.control-item').click(function() {
            thisSlick.slick('slickGoTo', $(this).index() );
          })

          $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var currentControl = $(this).parent().siblings('.controls').find('.control-item:eq(' + nextSlide + ')');
            currentControl.addClass('current-item');
            currentControl.siblings().removeClass('current-item');
          });

  			}

  		});

			var scroll = new SmoothScroll('a[href*="#"]', { speed: 400 });

			var menuToggle = $("#header-nav-toggle").unbind();

			menuToggle.on("click", function(e) {

				e.preventDefault();

				$('#header-nav').slideToggle(300, function(){
					if($('#header-nav').is(":hidden")) {
						$('#header-nav').removeAttr("style");
					}
				});

				$(this).toggleClass('opened');

				if ( $('#header-nav').hasClass( 'open' ) ) {
					$('#header-nav').toggleClass('open');
				} else {
					setTimeout( function() { $('#header-nav').toggleClass('open'); }, 200 );
				}

			});

			$('header#page-header.expanding-header ul.header-nav-menu > .menu-item-has-children').hover(

  			function() {

    			if ( $('#header-nav-toggle').is(':hidden') ) {

      			var subMenuHeight = $(this).children('ul.sub-menu').eq(0).outerHeight();
      			$('header#page-header').css('padding-bottom', subMenuHeight);

    			}

  			},

  			function() {

    			$('header#page-header').css('padding-bottom', '');

  			}

			);

			$(document).on('click', '.blocks-gallery-item a', lity);

		},
		// JavaScript to be fired on all pages, after page specific JS is fired
		finalize: function() {

//   		$(window).load(function() {
        $('body').removeClass('no-transition');
//       });

  		$('.metaslider img').removeAttr('title');

  		$('.slick-slideshow').each( function() {

    		var slickSettings = $(this).data();

    		$.each( slickSettings, function(k, v) {

      		var camelCased = k.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
      		delete slickSettings[k];
          slickSettings[camelCased] = v;

    		});

    		$(this).slick(slickSettings);

  		});

		}
	},
	// Home page
	'home': {
		// JavaScript to be fired on the home page
		init: function() {},
		// JavaScript to be fired on the home page, after the init JS
		finalize: function() {}
	},
	// About us page
	'about_us': {
		// JavaScript to be fired on the about us page
		init: function() {},
		// JavaScript to be fired on the about us page, after the init JS
		finalize: function() {}
	}
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var fire;
		var namespace = Sage;
		funcname = (funcname === undefined) ? 'init' : funcname;
		fire = func !== '';
		fire = fire && namespace[func];
		fire = fire && typeof namespace[func][funcname] === 'function';

		if (fire) {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		// Fire common init JS
		UTIL.fire('common');

		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
			UTIL.fire(classnm);
			UTIL.fire(classnm, 'finalize');
		});

		// Fire common finalize JS
		UTIL.fire('common', 'finalize');
	}
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
